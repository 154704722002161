<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <g-chart
          type="ColumnChart"
          :data="chartDataAnnuel"
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <g-chart
          type="LineChart"
          :data="chartDataHebdomadaire"
          :options="chartOptionHebdomadaire"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          title="Chiffre d'affaire"
          :value="CAMonth.toString()"
          sub-icon="mdi-calendar"
          :sub-text="libelleCa"
        />
        <v-btn
          style="padding: 10px 15px !important;margin:-right:0px !important"
          @click="getCa(1)"
        >
          Par jour
        </v-btn>
        <v-btn
          style="padding: 10px 15px !important;margin:-right:0px !important"
          @click="getCa(2)"
        >
          Par semaine
        </v-btn>
        <v-btn
          style="padding: 10px 15px !important;margin:-right:0px !important"
          @click="getCa(3)"
        >
          Par mois
        </v-btn>
        <v-btn
          style="padding: 10px 15px !important;margin:-right:0px !important"
          @click="getCa(4)"
        >
          Par An
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-sofa"
          title="Commandes"
          :value="NbCommandes.toString()"
          sub-icon="mdi-alert"
          sub-icon-color="red"
          sub-text="Payé ou en cours ou en Livraison"
        />
        <v-btn style="padding: 10px 15px !important;margin:-right:0px !important">
          Toutes
        </v-btn> <v-btn style="padding: 10px 15px !important;margin:-right:0px !important">
          En attente de paiement
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        lg="4"
        md="6"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-sofa"
          title="Panier Moyen"
          :value="PanierMoyen.toString()"
          sub-icon="mdi-alert"
          sub-icon-color="red"
          sub-text="Payé ou en cours ou en Livraison"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-sofa"
          title="Meilleur vente"
          sub-icon="mdi-alert"
          sub-icon-color="red"
          sub-text="A venir"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import { GChart } from 'vue-google-charts'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardDashboard',
    components: {
      GChart,
    },
    data () {
      return {
        websiteid: 1,
        chartDataAnnuel: [
          ['Month', 'CA'],
        ],
        chartOptionsAnnuel: {
          chart: {
            title: 'Company Performance',
            subtitle: 'CA',
          },
        },
        chartDataHebdomadaire: [
          ['Week', 'CA'],
        ],
        chartOptionHebdomadaire: {
          chart: {
            title: 'Company Performance',
            subtitle: 'CA',
          },
        },
        CAMonth: 0,
        NbCommandes: 0,
        PanierMoyen: 0,
        libelleCa: 'Ce mois-ci',
        dailySalesChart: {
          data: {
            labels: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
            series: [
              [12, 17, 7, 17, 23, 18, 38],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        emailsSubscriptionChart: {
          data: {
            labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
            series: [
              [1554, 4056, 302, 8967, 555, 33, 326, 434, 568, 610, 756, 895],

            ],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 1000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    beforeCreate () {
      this.websiteid = this.$cookie.get('website_id')
      // Récupération des commandes
      Vue.axios.get('https://api.espace-jantes.com/getChiffreAffaires', {
        params: { websiteid: this.websiteid },
      }).then((response) => {
        this.CAMonth = response.data.montantCA + '€'
        this.NbCommandes = response.data.data.length
        this.PanierMoyen = (response.data.montantCA / this.NbCommandes).toFixed(2)
        if (isNaN(this.PanierMoyen)) {
          this.PanierMoyen = 0
        }
        this.PanierMoyen = this.PanierMoyen + '€'
      })
      Vue.axios.get('https://api.espace-jantes.com/getChiffreAffairesAnnuelGraphique', {
        params: { websiteid: this.websiteid },
      }).then((response) => {
        var self = this
        response.data.data.forEach(function (value, index) {
          var arrayToPush = []
          arrayToPush.push(self.getMonthByIndex(index))
          arrayToPush.push(value)
          self.chartDataAnnuel.push(arrayToPush)
        })
      })
      Vue.axios.get('https://api.espace-jantes.com/getChiffreAffairesHebdomadaireGraphique', {
        params: { websiteid: this.websiteid },
      }).then((response) => {
        var self = this
        response.data.data.forEach(function (value, index) {
          var arrayToPush = []
          arrayToPush.push(self.getWeekByIndex(index))
          arrayToPush.push(value)
          self.chartDataHebdomadaire.push(arrayToPush)
        })
      })
    },
    methods: {
      getCa: function (typeTri) {
        Vue.axios.get('https://api.espace-jantes.com/getChiffreAffaires', {
          params: { typeTri: typeTri, websiteid: this.websiteid },
        }).then((response) => {
          this.CAMonth = response.data.montantCA + '€'
          this.NbCommandes = response.data.data.length
          this.PanierMoyen = (response.data.montantCA / this.NbCommandes).toFixed(2)
          if (isNaN(this.PanierMoyen)) {
            this.PanierMoyen = 0
          }
          this.PanierMoyen = this.PanierMoyen + '€'
        })
      },
      getMonthByIndex: function (index) {
        var month = ['Jan', 'Fev', 'Mars', 'Avr', 'Mai', 'Juin', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec']

        return month[index]
      },
      getWeekByIndex: function (index) {
        var month = ['L', 'M', 'M', 'J', 'V', 'S', 'D']

        return month[index]
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
<style>
.customContainer{
  max-width: 95% !important;
}
</style>
